import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChangePasswordRequest,
  changePasswordResponseErrorSchema
} from '../schema/api/changePasswordRequestSchema';
import { isEmpty } from '../utils/isEmpty';

interface Fields {
  current: string;
  password: string;
  confirm: string;
}

// If you change this type be sure to also update the corresponding
// state diagram in src/store/README.md. The state diagram should
// also reflect the state of the code.
type State = Fields &
  (
    | { name: 'blank' | 'valid' | 'loading' }
    | { name: 'complete' | 'error'; message: string }
    | {
        name: 'invalid';
        invalidCurrent: boolean;
        invalidPassword: boolean;
      }
  );

function isCurrentValid({ current }: Fields) {
  return !isEmpty(current);
}

function isPasswordValid({ password, confirm }: Fields) {
  return (
    !isEmpty(password) &&
    !isEmpty(confirm) &&
    password === confirm &&
    password.length >= 12
  );
}

// This function invocation allows us to widen the type.
// If we declare this constant without the function invocation
// we end up getting a narrowed type of State. When we pass
// initialState into the createSlice function it will use the
// narrowed type instead of State. We do not need the narrowed
// type anywhere so we widen it.
const initialState = ((state: State) => state)({
  name: 'blank',
  current: '',
  password: '',
  confirm: ''
});

export const changePassword = createAsyncThunk<
  void,
  ChangePasswordRequest,
  { rejectValue: Error }
>('change-password', async (values) => {
  const response = await fetch('/api/change-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(values)
  });

  if (!response.ok) {
    const { message } = changePasswordResponseErrorSchema.parse(
      await response.json()
    );
    throw new Error(message);
  }
});

export const changePasswordSlice = createSlice({
  name: 'change-password',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Fields>) => {
      if (
        state.name === 'complete' ||
        state.name === 'error' ||
        state.name === 'blank' ||
        state.name === 'valid' ||
        state.name === 'invalid'
      ) {
        const invalidCurrent = !isCurrentValid(action.payload);
        const invalidPassword = !isPasswordValid(action.payload);

        return invalidCurrent || invalidPassword
          ? {
              name: 'invalid',
              invalidCurrent,
              invalidPassword,
              ...action.payload
            }
          : { name: 'valid', ...action.payload };
      }
    },
    reset: (state) => {
      if (state.name === 'complete' || state.name === 'error') {
        return initialState;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(changePassword.pending, (state) => {
      if (state.name === 'valid') {
        state.name = 'loading';
      }
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      if (state.name === 'loading') {
        return {
          name: 'complete',
          message: 'form-response.save-password-successful',
          current: '',
          password: '',
          confirm: ''
        };
      }
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      if (state.name === 'loading') {
        return {
          ...state,
          name: 'error',
          // TODO: Maybe we should put some generic error message here?
          message: action.error.message ?? ''
        };
      }
    });
  }
});
