import cn from 'classnames';
import styles from './Title.module.scss';

export type TitleTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type TitleTheme = 'primary' | 'neutral' | 'white';

interface TitleProps {
  className?: string;
  children: React.ReactNode;
  titleTag: TitleTag;
  titleTheme?: TitleTheme;
}

export function Title({
  children,
  className,
  titleTag,
  titleTheme = 'white'
}: TitleProps) {
  const CustomHeading = titleTag as keyof JSX.IntrinsicElements;

  return (
    <CustomHeading
      className={cn(className, styles[`${titleTag}`], {
        [styles.primaryTheme]: titleTheme === 'primary',
        [styles.neturalTheme]: titleTheme === 'neutral',
        [styles.whiteTheme]: titleTheme === 'white'
      })}
    >
      {children}
    </CustomHeading>
  );
}
