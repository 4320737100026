import * as emailValidator from 'email-validator';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateRequest } from '../schema/api/updateRequestSchema';
import { isEmpty } from '../utils/isEmpty';

interface Fields {
  fullName: string;
  bornAt: string;
  email: string;
  confirm: string;
}

interface DefaultFields {
  defaultFullName: string;
  defaultBornAt: string;
  defaultEmail: string;
}

// If you change this type be sure to also update the corresponding
// state diagram in src/store/README.md. The state diagram should
// also reflect the state of the code.
type State = Fields &
  DefaultFields &
  (
    | { name: 'blank' | 'valid' | 'loading' }
    | { name: 'complete' | 'error'; message: string }
    | {
        name: 'invalid';
        invalidEmail: 'valid' | 'email' | 'confirm' | 'match';
        invalidFullName: boolean;
        invalidBornAt: boolean;
      }
  );

function isEmailValid({
  email,
  confirm,
  defaultEmail
}: Fields & DefaultFields) {
  return email === defaultEmail
    ? 'valid'
    : isEmpty(email) || !emailValidator.validate(email)
    ? 'email'
    : isEmpty(confirm)
    ? 'confirm'
    : confirm !== email
    ? 'match'
    : 'valid';
}

function isFullNameValid({
  fullName,
  defaultFullName
}: Fields & DefaultFields) {
  return fullName === defaultFullName || !isEmpty(fullName);
}

function isBornAtValid({ bornAt, defaultBornAt }: Fields & DefaultFields) {
  return bornAt === defaultBornAt || !isEmpty(bornAt);
}

function isBlank({
  fullName,
  email,
  bornAt,
  defaultFullName,
  defaultEmail,
  defaultBornAt
}: Fields & DefaultFields) {
  return (
    fullName === defaultFullName &&
    email === defaultEmail &&
    bornAt === defaultBornAt
  );
}

function nextState(state: Fields & DefaultFields): State {
  const invalidEmail = isEmailValid(state);
  const invalidFullName = !isFullNameValid(state);
  const invalidBornAt = !isBornAtValid(state);

  return invalidEmail !== 'valid' || invalidFullName || invalidBornAt
    ? {
        ...state,
        name: 'invalid',
        invalidEmail,
        invalidFullName,
        invalidBornAt
      }
    : { ...state, name: isBlank(state) ? 'blank' : 'valid' };
}

// This function invocation allows us to widen the type.
// If we declare this constant without the function invocation
// we end up getting a narrowed type of State. When we pass
// initialState into the createSlice function it will use the
// narrowed type instead of State. We do not need the narrowed
// type anywhere so we widen it.
const initialState = ((state: State) => state)({
  name: 'blank',
  fullName: '',
  bornAt: '',
  email: '',
  confirm: '',
  defaultFullName: '',
  defaultBornAt: '',
  defaultEmail: ''
});

export const update = createAsyncThunk<
  void,
  UpdateRequest,
  { rejectValue: Error }
>('update', async (values) => {
  const response = await fetch('/api/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(values)
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
});

export const updateSlice = createSlice({
  name: 'update',
  initialState,
  reducers: {
    init: (state, action: PayloadAction<DefaultFields>) => {
      if (state.name === 'valid' || state.name === 'invalid') {
        return nextState({ ...state, ...action.payload });
      }

      if (state.name === 'blank') {
        state.defaultFullName = action.payload.defaultFullName;
        state.defaultEmail = action.payload.defaultEmail;
        state.defaultBornAt = action.payload.defaultBornAt;

        state.fullName = action.payload.defaultFullName;
        state.email = action.payload.defaultEmail;
        state.bornAt = action.payload.defaultBornAt;
      }
    },
    update: (state, action: PayloadAction<Fields>) => {
      if (
        state.name === 'complete' ||
        state.name === 'error' ||
        state.name === 'blank' ||
        state.name === 'valid' ||
        state.name === 'invalid'
      ) {
        return nextState({ ...state, ...action.payload });
      }
    },
    reset: (state) => {
      if (
        state.name === 'blank' ||
        state.name === 'error' ||
        state.name === 'complete'
      ) {
        return initialState;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(update.pending, (state) => {
      if (state.name === 'valid') {
        state.name = 'loading';
      }
    });
    builder.addCase(update.fulfilled, (state) => {
      if (state.name === 'loading') {
        return {
          ...state,
          defaultFullName: state.fullName,
          defaultEmail: state.email,
          defaultBornAt: state.bornAt,
          confirm: '',
          name: 'complete',
          message: 'form-response.save-profile-successful'
        };
      }
    });
    builder.addCase(update.rejected, (state, action) => {
      if (state.name === 'loading') {
        return {
          ...state,
          name: 'error',
          // TODO: Maybe we should put some generic error message here?
          message: action.error.message ?? ''
        };
      }
    });
  }
});
