import React, { FunctionComponent, SVGProps } from 'react';
import cn from 'classnames';
import styles from './Icon.module.scss';

import ArrowUp from '../../public/images/icons/arrow-up.svg';
import ArrowDown from '../../public/images/icons/arrow-down.svg';
import ArrowLeft from '../../public/images/icons/arrow-left.svg';
import ArrowRight from '../../public/images/icons/arrow-right.svg';
import Plus from '../../public/images/icons/plus.svg';
import Minus from '../../public/images/icons/minus.svg';
import Check from '../../public/images/icons/check.svg';
import Close from '../../public/images/icons/close.svg';
import Info from '../../public/images/icons/info.svg';
import Warning from '../../public/images/icons/warning.svg';
import Menu from '../../public/images/icons/menu.svg';
import Play from '../../public/images/icons/play.svg';
import Flag from '../../public/images/icons/flag.svg';
import Lock from '../../public/images/icons/lock.svg';
import Clock from '../../public/images/icons/clock.svg';
import See from '../../public/images/icons/see.svg';
import Think from '../../public/images/icons/think.svg';
import Feel from '../../public/images/icons/feel.svg';
import Act from '../../public/images/icons/act.svg';
import Resources from '../../public/images/icons/resources.svg';
import Track from '../../public/images/icons/track.svg';
import Schedule from '../../public/images/icons/schedule.svg';
import Profile from '../../public/images/icons/profile.svg';
import SignOut from '../../public/images/icons/sign-out.svg';
import Optimism from '../../public/images/icons/optimism.svg';
import Control from '../../public/images/icons/control.svg';
import Confidence from '../../public/images/icons/confidence.svg';
import Balance from '../../public/images/icons/balance.svg';
import Regulation from '../../public/images/icons/regulation.svg';
import Mindfulness from '../../public/images/icons/mindfulness.svg';
import Decisiveness from '../../public/images/icons/decisiveness.svg';
import Planning from '../../public/images/icons/planning.svg';
import Literacy from '../../public/images/icons/literacy.svg';
import Containment from '../../public/images/icons/containment.svg';
import Motivation from '../../public/images/icons/motivation.svg';
import SelfCompassion from '../../public/images/icons/self-compassion.svg';
import Empathy from '../../public/images/icons/empathy.svg';
import Boundaries from '../../public/images/icons/boundaries.svg';
import Assertiveness from '../../public/images/icons/assertiveness.svg';
import Collaboration from '../../public/images/icons/collaboration.svg';
import Spinner from '../../public/images/icons/spinner.svg';
import SpinnerWhite from '../../public/images/icons/spinner--white.svg';
import Bookmark from '../../public/images/icons/bookmark.svg';
import Unsatisfied from '../../public/images/icons/unsatisfied.svg';
import Satisfied from '../../public/images/icons/satisfied.svg';
import VerySatisfied from '../../public/images/icons/very-satisfied.svg';
import VeryUnsatisfied from '../../public/images/icons/very-unsatisfied.svg';
import Neutral from '../../public/images/icons/neutral.svg';
import TabSelect from '../../public/images/icons/tab-select.svg';
import File from '../../public/images/icons/file.svg';
import { z } from 'zod';

export const iconNameSchema = z.enum([
  'arrowUp',
  'arrowDown',
  'arrowLeft',
  'arrowRight',
  'plus',
  'minus',
  'check',
  'close',
  'info',
  'warning',
  'menu',
  'play',
  'flag',
  'lock',
  'clock',
  'see',
  'think',
  'feel',
  'act',
  'resources',
  'track',
  'schedule',
  'profile',
  'signOut',
  'optimism',
  'control',
  'confidence',
  'balance',
  'regulation',
  'mindfulness',
  'decisiveness',
  'planning',
  'literacy',
  'containment',
  'motivation',
  'selfCompassion',
  'empathy',
  'boundaries',
  'assertiveness',
  'collaboration',
  'spinner',
  'spinnerWhite',
  'satisfied',
  'unsatisfied',
  'veryUnsatisfied',
  'verySatisfied',
  'neutral',
  'bookmark',
  'tabSelect',
  'file'
]);

export type IconName =
  | 'arrowUp'
  | 'arrowDown'
  | 'arrowLeft'
  | 'arrowRight'
  | 'plus'
  | 'minus'
  | 'check'
  | 'close'
  | 'info'
  | 'warning'
  | 'menu'
  | 'play'
  | 'flag'
  | 'lock'
  | 'clock'
  | 'see'
  | 'think'
  | 'feel'
  | 'act'
  | 'resources'
  | 'track'
  | 'schedule'
  | 'profile'
  | 'signOut'
  | 'optimism'
  | 'control'
  | 'confidence'
  | 'balance'
  | 'regulation'
  | 'mindfulness'
  | 'decisiveness'
  | 'planning'
  | 'literacy'
  | 'containment'
  | 'motivation'
  | 'selfCompassion'
  | 'empathy'
  | 'boundaries'
  | 'assertiveness'
  | 'collaboration'
  | 'spinner'
  | 'spinnerWhite'
  | 'satisfied'
  | 'unsatisfied'
  | 'veryUnsatisfied'
  | 'verySatisfied'
  | 'neutral'
  | 'bookmark'
  | 'tabSelect'
  | 'file';

export type IconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type IconTheme = 'default' | 'light';

interface IconData {
  component: FunctionComponent<SVGProps<SVGSVGElement>>;
}

type IconDataMap = { [key in IconName]: IconData };

const iconData: IconDataMap = {
  arrowUp: {
    component: ArrowUp
  },
  arrowDown: {
    component: ArrowDown
  },
  arrowRight: {
    component: ArrowRight
  },
  arrowLeft: {
    component: ArrowLeft
  },
  plus: {
    component: Plus
  },
  minus: {
    component: Minus
  },
  check: {
    component: Check
  },
  close: {
    component: Close
  },
  info: {
    component: Info
  },
  warning: {
    component: Warning
  },
  menu: {
    component: Menu
  },
  play: {
    component: Play
  },
  flag: {
    component: Flag
  },
  lock: {
    component: Lock
  },
  clock: {
    component: Clock
  },
  see: {
    component: See
  },
  think: {
    component: Think
  },
  feel: {
    component: Feel
  },
  act: {
    component: Act
  },
  resources: {
    component: Resources
  },
  track: {
    component: Track
  },
  schedule: {
    component: Schedule
  },
  profile: {
    component: Profile
  },
  signOut: {
    component: SignOut
  },
  optimism: {
    component: Optimism
  },
  control: {
    component: Control
  },
  confidence: {
    component: Confidence
  },
  balance: {
    component: Balance
  },
  regulation: {
    component: Regulation
  },
  mindfulness: {
    component: Mindfulness
  },
  decisiveness: {
    component: Decisiveness
  },
  planning: {
    component: Planning
  },
  literacy: {
    component: Literacy
  },
  containment: {
    component: Containment
  },
  motivation: {
    component: Motivation
  },
  selfCompassion: {
    component: SelfCompassion
  },
  empathy: {
    component: Empathy
  },
  boundaries: {
    component: Boundaries
  },
  assertiveness: {
    component: Assertiveness
  },
  collaboration: {
    component: Collaboration
  },
  spinner: {
    component: Spinner
  },
  spinnerWhite: {
    component: SpinnerWhite
  },
  bookmark: {
    component: Bookmark
  },
  satisfied: {
    component: Satisfied
  },
  unsatisfied: {
    component: Unsatisfied
  },
  verySatisfied: {
    component: VerySatisfied
  },
  veryUnsatisfied: {
    component: VeryUnsatisfied
  },
  neutral: {
    component: Neutral
  },
  tabSelect: {
    component: TabSelect
  },
  file: {
    component: File
  }
};

interface IconProps {
  className?: string;
  name: IconName;
  size?: IconSize;
  theme?: IconTheme;
}

export function Icon({
  className = '',
  name = 'arrowUp',
  size = 'md',
  theme = 'default'
}: IconProps) {
  const { component } = iconData[name];

  return React.createElement(component, {
    className: cn(
      styles.icon,
      styles[`${name}`],
      styles[`${size}`],
      className,
      {
        [styles.light]: theme === 'light'
      }
    ),
    'aria-label': `${name}`
  });
}
