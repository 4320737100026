import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  pointsResultBodySchema,
  PointsResultBodyType
} from '../schema/api/pointsSchema';

export interface PointsState extends PointsResultBodyType {
  loading: boolean;
}

const initialState: PointsState = {
  loading: false,
  totalPoints: 0,
  acknowledgedTotalPoints: 0,
  showNotification: false
};

export const getPoints = createAsyncThunk<
  PointsResultBodyType,
  void,
  { rejectValue: Error }
>('points-get', async () => {
  const res = await fetch('/api/points-get', {
    method: 'GET'
  });

  if (!res.ok) {
    throw new Error(`error.${res.status}`);
  }

  const result = pointsResultBodySchema.parse(await res.json());

  return result;
});

export const acknowledgePoints = createAsyncThunk<
  PointsResultBodyType,
  number,
  { rejectValue: Error }
>('points-acknowledge', async (totalPoints) => {
  const res = await fetch('/api/points-acknowledge', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ totalPoints })
  });

  if (!res.ok) {
    throw new Error(`error.${res.status}`);
  }

  const result = pointsResultBodySchema.parse(await res.json());

  return result;
});

export const pointsSlice = createSlice({
  name: 'points',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPoints.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPoints.fulfilled, (state, action) => {
        state.loading = false;
        state.totalPoints = action.payload.totalPoints;
        state.acknowledgedTotalPoints = action.payload.acknowledgedTotalPoints;
        state.showNotification = action.payload.showNotification;
      })
      .addCase(getPoints.rejected, (state) => {
        state.loading = false;
      })
      .addCase(acknowledgePoints.pending, (state) => {
        state.loading = true;
      })
      .addCase(acknowledgePoints.fulfilled, (state, action) => {
        state.loading = false;
        state.acknowledgedTotalPoints = action.payload.acknowledgedTotalPoints;
        state.showNotification = action.payload.showNotification;
      })
      .addCase(acknowledgePoints.rejected, (state) => {
        state.loading = false;
      });
  }
});
