import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CheckIn } from '../components/DailyCheckIn';
import {
  userCheckInResponseBodySchema,
  UserCheckInResponseBodyType
} from '../schema/api/userCheckInResponseSchema';
export interface CheckInState {
  loading: boolean;
  success?: boolean;
  errorMessage?: string;
}

const initialState: CheckInState = {
  loading: false
};

export const postCheckIn = createAsyncThunk<
  UserCheckInResponseBodyType,
  CheckIn,
  { rejectValue: Error }
>('checkin', async (values: CheckIn) => {
  const response = await fetch('/api/daily-check-in-response', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(values)
  });

  if (!response.ok) {
    throw new Error(`error.${response.status}`);
  }

  const data = userCheckInResponseBodySchema.parse(await response.json());
  return data;
});

export const checkInSlice = createSlice({
  name: 'checkin',
  initialState,
  reducers: {
    reset: (state) => {
      state.success = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(postCheckIn.pending, (state) => {
      state.loading = true;
      state.success = undefined;
      state.errorMessage = undefined;
    });
    builder.addCase(postCheckIn.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(postCheckIn.rejected, (state, action) => {
      state.loading = false;
      state.errorMessage = action.error.message;
    });
  }
});
