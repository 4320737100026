import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HelpRequest } from '../schema/api/helpRequestSchema';
import { isEmpty } from '../utils/isEmpty';

interface Fields {
  description: string;
}

// If you change this type be sure to also update the corresponding
// state diagram in src/store/README.md. The state diagram should
// also reflect the state of the code.
type State = Fields &
  (
    | { name: 'blank' | 'valid' | 'loading' }
    | { name: 'complete' | 'error'; message: string }
  );

function isDescriptionValid({ description }: Fields) {
  return !isEmpty(description);
}

// This function invocation allows us to widen the type.
// If we declare this constant without the function invocation
// we end up getting a narrowed type of State. When we pass
// initialState into the createSlice function it will use the
// narrowed type instead of State. We do not need the narrowed
// type anywhere so we widen it.
const initialState = ((state: State) => state)({
  name: 'blank',
  description: ''
});

export const help = createAsyncThunk<void, HelpRequest, { rejectValue: Error }>(
  'help',
  async (values) => {
    const response = await fetch('/api/help', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }
  }
);

export const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Fields>) => {
      if (
        state.name === 'complete' ||
        state.name === 'error' ||
        state.name === 'blank' ||
        state.name === 'valid'
      ) {
        return {
          name: isDescriptionValid(action.payload) ? 'valid' : 'blank',
          ...action.payload
        };
      }
    },
    reset: (state) => {
      if (state.name === 'complete' || state.name === 'error') {
        return initialState;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(help.pending, (state) => {
      if (state.name === 'valid') {
        state.name = 'loading';
      }
    });
    builder.addCase(help.fulfilled, (state) => {
      if (state.name === 'loading') {
        return {
          name: 'complete',
          message: 'profile.help.success',
          description: ''
        };
      }
    });
    builder.addCase(help.rejected, (state, action) => {
      if (state.name === 'loading') {
        return {
          ...state,
          name: 'error',
          // TODO: Maybe we should put some generic error message here?
          message: action.error.message ?? ''
        };
      }
    });
  }
});
