import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SurveyResponse } from '../schema/surveyResponseSchema';

interface SurveyState {
  loading: boolean;
  success?: boolean;
  errorMessage?: string;
}

interface PostSurveyResponseValues {
  results: SurveyResponse;
  surveyId?: string;
}

const initialState: SurveyState = {
  loading: false
};

export const postSurveyResponse = createAsyncThunk<
  number,
  PostSurveyResponseValues,
  { rejectValue: Error }
>('survey', async ({ results, surveyId }) => {
  const response = await fetch('/api/user-survey-response', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      response: results,
      surveyId
    })
  });

  if (!response.ok) {
    throw new Error(`error.${response.status}`);
  }
  return response.status;
});

export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    setSuccessFalse(state) {
      state.success = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(postSurveyResponse.pending, (state) => {
      state.loading = true;
      state.success = undefined;
      state.errorMessage = undefined;
    });
    builder.addCase(postSurveyResponse.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(postSurveyResponse.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.errorMessage = 'error.500';
    });
  }
});

export const { setSuccessFalse } = surveySlice.actions;
