import { z } from 'zod';

export const changePasswordRequestSchema = z.object({
  current: z.string().min(1),
  password: z.string().min(1),
  confirm: z.string().min(1)
});

export const changePasswordResponseErrorSchema = z.object({
  message: z.string()
});

export type ChangePasswordRequest = z.infer<typeof changePasswordRequestSchema>;
