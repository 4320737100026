import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { signIn } from 'next-auth/react';
import { CreateProfileRequest } from '../schema/api/userProfileSchema';

interface ProfileState {
  loading: boolean;
  message?: string;
  errorMessage?: string;
}

const initialState: ProfileState = {
  loading: false
};

export const createProfile = createAsyncThunk<
  number,
  CreateProfileRequest,
  { rejectValue: Error }
>('create-profile', async (values) => {
  const res = await fetch('/api/create-profile', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(values)
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  signIn('credentials', {
    usernameOrEmail: values.username,
    password: values.password
  });

  return res.status;
});

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createProfile.pending, (state) => {
        state.message = undefined;
        state.errorMessage = undefined;
        state.loading = true;
      })
      .addCase(createProfile.fulfilled, (state) => {
        state.message = 'form-response.create-profile-successful';
        state.errorMessage = undefined;
        state.loading = false;
      })
      .addCase(createProfile.rejected, (state, action) => {
        state.message = undefined;
        state.errorMessage = action.error.message;
        state.loading = false;
      });
  }
});
