import cn from 'classnames';
import Image from 'next/legacy/image';
import { useTranslation } from 'next-i18next';
import { Icon } from './Icon';
import styles from './Loader.module.scss';
import { Title } from './Title';

interface LoaderProps {
  hasHeaderAndFooter?: boolean;
  isReverse?: boolean;
  className?: string;
}

export function Loader({
  isReverse,
  hasHeaderAndFooter,
  className
}: LoaderProps) {
  const { t } = useTranslation();

  return (
    <div
      className={cn(styles.container, className, {
        [styles.isReverse]: isReverse
      })}
    >
      {hasHeaderAndFooter && (
        <header className={styles.header}>
          <div className={styles.imageContainer}>
            <Image
              src="/images/logo--white.svg"
              layout="fill"
              priority={true}
            />
          </div>
        </header>
      )}
      <main className={styles.main}>
        <div className={styles.loading}>
          <Icon
            name={isReverse ? 'spinner' : 'spinnerWhite'}
            className={styles.spinner}
          />
          <Title titleTag="h6" className={styles.text}>
            {t('loader-text')}
          </Title>
        </div>
      </main>
      {hasHeaderAndFooter && <footer className={styles.footer} />}
    </div>
  );
}
