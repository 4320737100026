import { combineReducers } from '@reduxjs/toolkit';
import { activitySlice } from './activity';
import { profileSlice } from './profile';
import { pointsSlice } from './points';
import { helpSlice } from './help';
import { checkInSlice } from './checkin';
import { surveySlice } from './survey';
import { changePasswordSlice } from './change-password';
import { updateSlice } from './update';

export const reducer = combineReducers({
  [activitySlice.name]: activitySlice.reducer,
  [profileSlice.name]: profileSlice.reducer,
  [pointsSlice.name]: pointsSlice.reducer,
  [helpSlice.name]: helpSlice.reducer,
  [checkInSlice.name]: checkInSlice.reducer,
  [surveySlice.name]: surveySlice.reducer,
  [changePasswordSlice.name]: changePasswordSlice.reducer,
  [updateSlice.name]: updateSlice.reducer
});
