import { MoodType } from '@prisma/client';
import { z } from 'zod';

const valueMoodType = [
  MoodType.VERY_UNSATISFIED,
  MoodType.UNSATISFIED,
  MoodType.NEUTRAL,
  MoodType.SATISFIED,
  MoodType.VERY_SATISFIED
] as const;

export const userCheckInResponseSchema = z.object({
  stress: z.number(),
  energy: z.number(),
  mood: z.enum(valueMoodType),
  text: z.string(),
  previousUrl: z.string().optional()
});

export const userCheckInResponseBodySchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  stress: z.number(),
  energy: z.number(),
  mood: z.enum(valueMoodType),
  text: z.string().nullable(),
  userId: z.string()
});

export type UserCheckInResponseBodyType = z.infer<
  typeof userCheckInResponseBodySchema
>;
