import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import debounce from 'lodash.debounce';
import { SaveActivityValueRequest } from '../schema/api/saveActivityValueSchema';
import { SaveActivityResponseRequest } from '../schema/api/saveActivityResponseSchema';
import { SaveActivityPracticeRequest } from '../schema/api/saveActivityPracticeSchema';
import { SaveActivityWorkInResponseRequest } from '../schema/api/saveActivityWorkInResponseSchema';

// Prevent frequent API calls for rapidly changing interactive values.
const DEBOUNCE_TIME_MS = 250;

interface State {
  values: Record<string, Record<string, unknown>>;
}

// This function invocation allows us to widen the type.
// If we declare this constant without the function invocation
// we end up getting a narrowed type of State. When we pass
// initialState into the createSlice function it will use the
// narrowed type instead of State. We do not need the narrowed
// type anywhere so we widen it.
const initialState = ((state: State) => state)({
  values: {}
});

export const saveActivityValue = createAsyncThunk<
  void,
  SaveActivityValueRequest,
  { rejectValue: Error }
>(
  'save-value',
  debounce(async (request: SaveActivityValueRequest) => {
    const response = await fetch('/api/activity-value', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    });
    if (!response.ok) {
      throw new Error(`error.${response.status}`);
    }
  }, DEBOUNCE_TIME_MS)
);

export const saveActivityResponse = createAsyncThunk<
  void,
  SaveActivityResponseRequest,
  { rejectValue: Error }
>('save-activity-response', async (request) => {
  const response = await fetch('/api/activity-response', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  });

  if (!response.ok) {
    throw new Error(`error.${response.status}`);
  }
});

export const saveActivityWorkInResponse = createAsyncThunk<
  void,
  SaveActivityWorkInResponseRequest,
  { rejectValue: Error }
>('save-activity-work-in-response', async (request) => {
  const response = await fetch('/api/activity-work-in-response', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  });

  if (!response.ok) {
    throw new Error(`error.${response.status}`);
  }
});

export const saveActivityPracticeResponse = createAsyncThunk<
  void,
  SaveActivityPracticeRequest,
  { rejectValue: Error }
>('save-activity-practice-response', async (request) => {
  const response = await fetch('/api/activity-practice-response', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  });

  if (!response.ok) {
    throw new Error(`error.${response.status}`);
  }
});

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    load: (
      state,
      action: PayloadAction<{
        exerciseVersionId: string;
        values: Record<string, unknown>;
      }>
    ) => {
      if (state.values[action.payload.exerciseVersionId] === undefined) {
        state.values[action.payload.exerciseVersionId] = action.payload.values;
      }
    },
    save: (
      state,
      action: PayloadAction<{
        id: string;
        value: unknown;
        exerciseVersionId: string;
      }>
    ) => {
      state.values[action.payload.exerciseVersionId][action.payload.id] =
        action.payload.value;
    },
    reset: (
      state,
      action: PayloadAction<{
        exerciseVersionId: string;
      }>
    ) => {
      delete state.values[action.payload.exerciseVersionId];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(saveActivityValue.pending, (state, action) => {
      state.values[action.meta.arg.exerciseVersionId][action.meta.arg.id] =
        action.meta.arg.value;
    });
  }
});
