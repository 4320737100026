import { appWithTranslation } from 'next-i18next';
import { NextPage } from 'next';
import { useState, ReactElement, useEffect } from 'react';
import { AppProps } from 'next/dist/shared/lib/router/router';
import ReactModal from 'react-modal';
import { SessionProvider, useSession } from 'next-auth/react';
import { Provider } from 'react-redux';
import { store } from '../store';
import { useRouter } from 'next/router';
import { Loader } from '../components/Loader';
import Head from 'next/head';
import {
  init,
  getSessionId,
  setUserId,
  setGroup
} from '@amplitude/analytics-browser';

import 'core-js/actual/array/at';

import '../styles/globals.scss';
import '../styles/variables.scss';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactElement;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

ReactModal.setAppElement('#__next');

// This allows access to the session since it has to be called within
// the <SessionProvider> block
function Init() {
  const session = useSession();

  useEffect(() => {
    if (
      session.status === 'authenticated' ||
      session.status === 'unauthenticated'
    ) {
      if (process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY && session.data?.user) {
        // some events run on the client like external page clicks so we need
        // the user ID for those
        setUserId(session.data?.user.id);
        setGroup('organizationId', session.data?.user.organizationId);
      }
    }
  }, [session]);

  return <></>;
}

function MyApp({
  Component,
  pageProps: { session, ...pageProps }
}: AppPropsWithLayout) {
  const [loading, setLoading] = useState<boolean>(false);
  const { events } = useRouter();
  const getLayout = Component.getLayout ?? ((page) => page);

  // this runs on the client and server
  // getSessionId will tell us if it's already been initialized before
  if (process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY && !getSessionId()) {
    init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY);
  }

  useEffect(() => {
    const handleLoadingOn = () => {
      sessionStorage.setItem('previousUrl', window.location.pathname);
      setLoading(true);
    };
    const handleLoadingOff = () => setLoading(false);

    events.on('routeChangeStart', handleLoadingOn);
    events.on('routeChangeComplete', handleLoadingOff);
    events.on('routeChangeError', handleLoadingOff);

    return () => {
      events.off('routeChangeStart', handleLoadingOn);
      events.off('routeChangeComplete', handleLoadingOff);
      events.off('routeChangeError', handleLoadingOff);
    };
  }, [events, loading]);

  return getLayout(
    <div>
      <Head>
        <title>AIR Space</title>
      </Head>
      <Provider store={store}>
        <SessionProvider session={session}>
          <Init />
          {loading ? (
            <Loader hasHeaderAndFooter={true} />
          ) : (
            <Component {...pageProps} />
          )}
        </SessionProvider>
      </Provider>
    </div>
  );
}

export default appWithTranslation(MyApp);
