import { z } from 'zod';
import { RewardType } from '@prisma/client';

const validRewardTypes = [
  RewardType.CHECK_IN,
  RewardType.BASELINE_SURVEY,
  RewardType.INTRO_VIDEO,
  RewardType.VIDEO,
  RewardType.PODCAST,
  RewardType.MEDITATION,
  RewardType.LEARNING,
  RewardType.PRACTICE,
  RewardType.EXERCISE,
  RewardType.BADGE
] as const;

export const pointsEarnSchema = z.object({
  reward: z.enum(validRewardTypes)
});

export const pointsAcknowledgeSchema = z.object({
  totalPoints: z.number()
});

export const pointsResultBodySchema = z.object({
  totalPoints: z.number(),
  acknowledgedTotalPoints: z.number(),
  showNotification: z.boolean()
});

export type PointsResultBodyType = z.infer<typeof pointsResultBodySchema>;
